import { lazy } from 'react';
import { paths } from '../paths';
import { DefaultLayout } from '../../layouts/index';
import { Routes } from '../types/use-routes.interface';

// PAGE_IMPORTS
const Home = lazy(() => import('../../modules/landingpage/home/home'));
const AboutUs = lazy(
  () => import('../../modules/landingpage/about-us/components/about-us/about-us')
);

const CattaliniCase = lazy(() => import('../../modules/cases/cattalini/cattalini-case'));
const AgyxCase = lazy(() => import('../../modules/cases/agyx/agyx-case'));
const UrbiaCase = lazy(() => import('../../modules/cases/urbia/urbia-case'));
const CenterNorteCase = lazy(() => import('../../modules/cases/centernorte/centernorte-case'));
const CrmCase = lazy(() => import('../../modules/cases/crm/crm-case'));

const ContactUs = lazy(
  () => import('../../modules/landingpage/contact-us/components/contact-us/contact-us')
);

export const mainRoutes: Routes[] = [
  {
    path: paths.home.root,
    element: (
      <DefaultLayout>
        <Home />
      </DefaultLayout>
    ),
  },
  {
    path: paths.aboutUs.root,
    element: (
      <DefaultLayout>
        <AboutUs />
      </DefaultLayout>
    ),
  },
  {
    path: paths.contactUs.root,
    element: <ContactUs />,
  },
  {
    path: paths.cases.root,
    children: [
      {
        path: paths.cases.crm,
        element: <CrmCase />,
      },
      {
        path: paths.cases.agyx,
        element: <AgyxCase />,
      },
      {
        path: paths.cases.cattalini,
        element: <CattaliniCase />,
      },
      {
        path: paths.cases.urbia,
        element: <UrbiaCase />,
      },
      {
        path: paths.cases.centernorte,
        element: <CenterNorteCase />,
      },
    ],
  },
];
