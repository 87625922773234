import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  version?: 'white' | 'blue';
  width?: number | string;
  height?: number | string;
  url?: string;
  customLogo?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, version = 'white', height = 100, url = '/', width = 100, customLogo, sx, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="img"
        src={customLogo || "/logo/logo_white_color.png"}
        sx={{ width, height, cursor: 'pointer', ...sx }}
        {...other}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href={url} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
